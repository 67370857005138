<!-- <app-header></app-header>
<section class="accountSec main-body bg-gray-7 h-100">
  <div class="container d-flex flex-column h-100">
    <ul class="breadcrumb my-3">
      <li><a href="#">Manage Account</a></li>
      <li>Manage Brand</li>
    </ul>
    <div
      class="accountSecInner br12 bg-white page-shadow h-82vh overflow-hidden"
      id="row-main"
    >
      <app-account-sidebar
        class="col-md-3 pe-0 pb-0 border-end position-relative sidebar d-flex flex-column h-100"
        id="sidebar-left"
      ></app-account-sidebar>
      <div class="col-md-9 ps-0 d-flex flex-column h-100" id="content"> -->

<div class="video-header row m-0 ms-3 px-4 pt-4 ps-0">
  <p class="h3 weight-600 text-gray-2 pb-1 px-0 col-lg-8 mb-0">
    <a href="javascript:void(0)" routerLink="/account-setting/manage-brand" class="me-2">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.01519 15.1432C8.21658 15.3392 8.21658 15.657 8.01519 15.853C7.81379 16.049 7.48727 16.049 7.28588 15.853L0.55383 9.30125C-0.18461 8.58259 -0.18461 7.41741 0.55383 6.69874L7.28588 0.146999C7.48727 -0.0489997 7.81379 -0.0489997 8.01519 0.146999C8.21658 0.342998 8.21658 0.660776 8.01519 0.856774L1.28314 7.40852C1.2526 7.43824 1.22484 7.46964 1.19986 7.50241L15.4823 7.50241C15.7682 7.50241 16 7.72627 16 8.00242C16 8.27856 15.7682 8.50242 15.4823 8.50242L1.20358 8.50243C1.22759 8.53345 1.25411 8.56323 1.28314 8.59148L8.01519 15.1432Z"
          fill="#404243" />
      </svg>
    </a>
    {{
    this.brandDetail.name && this.brandDetail.name != ""
    ? this.brandDetail.name
    : "Business Name"
    }}
  </p>
</div>
<form [formGroup]="updateForm">
  <div class="container mx-0 ms-4 ps-1 business-media" id="container-view">
    <div class="row filter-post account-heading">
      <div class="col-md-6 p-0">
        <ul class="breadcrumb-tabs nav m-0">
          <li class="nav-item ps-0">
            <a href="javascript:void(0)" (click)="loadBrandAccount()" class="nav-link">Account</a>
          </li>
          <li class="nav-item">
            <a (click)="loadBrandBusiness()" href="javascript:void(0)" class="nav-link active">Business Details</a>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" (click)="loadBrandMediaLibraryList()" class="nav-link">Media Libraries</a>
          </li>
        </ul>
      </div>
      <div class="col-md-6 text-end edit-sec">
        <a href="javascript:void(0)" *ngIf="!this.isEdit" class="weight-normal btn btn-secondary btn-round-6 px-4 py-3" (click)="editBusinessBrand()">Edit</a>
        <a class="text-gray-3 text-sm me-4" *ngIf="this.isEdit" href="javascript:void(0)" (click)="cancelBusinessBrand()">Cancel Edits</a>
        <button type="submit" *ngIf="this.isEdit" class="weight-normal btn btn-secondary btn-round-6 px-4 py-3" (click)="updateBusinessBrand()" [disabled]="!this.updateForm.valid">
          Save Changes
        </button>
      </div>
    </div>
  </div>
  <div class="px-4 flex-grow-1 ps-0">
    <div class="" id="container-view">
      <form [formGroup]="updateForm">
        <div class="row ps-3 mt-4">
          <!-- <div class="col-md-2">
                      <div class="right-sde-info">

                        <div *ngIf="brandLogoPath == undefined || brandLogoPath == ''" class="logo-bg" >
                          <div><mat-icon class="person">person</mat-icon>
                          <mat-icon class="upload-new" (click)="file.click()">camera_alt</mat-icon>
                        </div>
                          <div class="upload-img"> <input
                              class="upload-file"
                              type="file" #file
                              accept="image/*"
                              (change)="uploadBrandLogo($event)"/></div>
                        </div>
                        <div class="logo-bg" *ngIf="brandLogoPath != undefined && brandLogoPath != ''"  [ngStyle]="{'background-image': 'url(' + brandLogoPath + ')'}" >
                              <div class="main-mat"><mat-icon class="upload-new"(click)="file.click()">edit</mat-icon></div>
                              <div class="upload-img"> <input
                                  class="upload-file"
                                  type="file" #file
                                  accept="image/*"
                                  (change)="uploadBrandLogo($event)"
                                /></div>
                        </div>
                        <p *ngIf="brandLogoPath != undefined && brandLogoPath != ''" class="text-danger mt-2 ms-4"> <a (click)="deleteBrandPicModal()">
                          <img src="../../../../assets/images/Delete.svg" width="18px" height="16px">
                          Remove</a></p>
                      </div>
                    </div> -->
          <div class="col-md-6">
            <div class="formItm mb-5">
              <input id="Business-Name" type="text" [disabled]="!this.isEdit" *ngIf="!this.isEdit && this.brandDetail" value="{{
                  this.brandDetail?.name
                    ? this.brandDetail?.name
                    : 'Business Name'
                }}" placeholder="Lakme Fashion" name="textfeild" required />

              <input id="Business-Name" type="text" formControlName="name" *ngIf="this.isEdit" placeholder="Lakme Fashion" name="textfeild" required />
              <label for="Business-Name" class="text-sm">Business Name</label>
              <span *ngIf="
                  updateForm?.controls['name']?.hasError('required') &&
                  this.isEdit &&
                  (updateForm?.controls['name']?.touched || updateFormSubmitted)
                " class="error">
                Please enter business name.</span>
            </div>
            <div class="formItm">
              <input id="Website-Name" type="text" [disabled]="!this.isEdit" *ngIf="!this.isEdit" value="{{
                  this.brandDetail.website
                    ? this.brandDetail.website
                    : 'website Name'
                }}" placeholder="lakme.in/fashion" name="textfeild" required />

              <input id="Website-Name" type="text" formControlName="website" *ngIf="this.isEdit" placeholder="lakme.in/fashion" name="textfeild" required />
              <label for="Website-Name" class="text-sm">Website</label>
              <span *ngIf="
                  updateForm?.controls['website']?.hasError('required') &&
                  this.isEdit &&
                  (updateForm?.controls['website']?.touched ||
                    updateFormSubmitted)
                " class="error">
                Please enter website.</span>
            </div>
          </div>
          <div class="col-md-4 ps-5">
            <ul class="selct_business">
              <p class="text-lg text-gray-2 px-0 mx-0 pt-2 mb-3">Industry</p>
              <p>
                <a href="javascripr:void(0)" class="">
                  <figure class="bg-primary-4 px-auto py-auto industry-icon">
                    <img *ngIf="
                        this.selectedIndustryIcon &&
                        this.selectedIndustryIcon != ''
                      " [src]="selectedIndustryIcon" />
                    <img *ngIf="
                        !this.selectedIndustryIcon ||
                        this.selectedIndustryIcon == ''
                      " src="../../../../../assets/images/generic industry.svg" />
                  </figure>
                  <span *ngIf="!this.isEdit" class="text-center text-xs text-gray-2">{{
                    this.brandDetail.industry
                    ? this.brandDetail.industry
                    : "Industry Name"
                    }}</span>
                </a>
              </p>
              <div class="formItm" *ngIf="this.isEdit">
                <div class="dropdown dropdownSelect">
                  <button class="dropdown-toggle text-md text-capitalize" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {{
                    selectedIndustry && selectedIndustry != ""
                    ? selectedIndustry
                    : "Please select industry"
                    }}
                  </button>
                  <ul class="dropdown-menu dropdownMenu font-list">
                    <li class="industry-list-item" *ngFor="let industry of industryList" (click)="selectIndustry(industry)">
                      <a class="dropdown-item text-md" (click)="selectIndustry(industry)">{{ industry.name }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div class="row ps-2 m-0 border-top">
          <div class="col-md-12">
            <p class="text-lg text-gray-2 px-0 mx-0 my-3 pb-2">Brand Kit</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="formItm static_lable order_list">
              <label for="" class="text-sm d-block mb-2"> Brand Logo </label>

              <figure *ngFor="let brand of brandLogoList; let i = index" class="f-left mb-2 me-2 brandFigure" [ngClass]="{'active' : this.brandDetail?.logo_file == brand.fpath}">

                <img [src]="brand.src" class="brand_logo brand-logo-size" />
                <mat-icon class="green-tik check_circle" (click)="selectedLogoFile(i, brand.fpath)">check_circle</mat-icon>
                <div class="logo-overlay">
                  <div type="radio" class="no-tik" (click)="selectedLogoFile(i, brand.fpath)"></div>
                  <a class="delete-icon">
                    <img src="../../../../assets/images/Delete.svg" (click)="deleteLogo(brand.fpath, i)" width="18px" height="16px" />
                  </a>
                </div>
              </figure>

              <p class="text-xm ps-1 clear font-brand-logo">
                Select primary logo
              </p>
              <input class="upload-file" type="file" #file accept="image/*" (change)="uploadBrandLogo($event)" />
              <button id="custom-button-svg" (click)="file.click()" class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad btnShadow d-flex align-items-center mt-2 py-1">
                <span class="upload-icon"></span>
                {{
                this.brandDetail?.logo_file &&
                this.brandDetail?.logo_file != ""
                ? "Upload More"
                : "Upload a logo or
                Drag and Drop"
                }}
              </button>
              <p class="text-xm ps-1 font-brand-logo sm">
                Supported formats (.jpg, .png)
              </p>
            </div>
          </div>
          <div class="col-md-4 ps-5" style="">
            <div class="submenu-floating-panel submenuFontWeight fency-scroll" (window:resize)="closeFontWeightSubmenu()" #fontSubMeu>

              <div class="item truncate-line" *ngFor="let font of submenuFontWeightList">
                <mat-icon class="green-tik-font" [ngClass]="{ active: this.selectedFont == font.name }">check_circle</mat-icon>
                <div type="radio" class="no-tik-font"  *ngIf="this.selectedFont != font.name" [ngClass]="{ 'show': this.selectedFont != font.name }" (click)="selectFont(font.name)"></div>
                <a class="dropdown-item text-md truncate-line " style=" border:1px; font-family:'{{font.styleName}}'"

                >{{ font.titleName}}
                </a>
              </div>
            </div>
            <div class="formItm down_arrow pt-2" style="width:250px;">
              <p class="text-md text-gray-3 mb-0">Brand Fonts</p>
              <div class="dropdown dropdownSelect">
                <button class="dropdown-toggle text-md text-capitalize truncate-line" style=" font-family:'{{selectedFontStyle}}'"  type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {{
                  selectedFont != ""
                  ? [selectedFont | fontFileNameTruncate : 50]
                  : "Brand Fonts"
                  }}
                </button>
                <ul class="dropdown-menu dropdownMenu font-list brand-font-list" aria-labelledby="dropdownMenuClickableInside">
                  <li>
                    <p *ngIf="brandFontArray.length > 0" class="text-lg text-gray-2 mb-0 ms-3 pt-3 pb-0">
                      <strong>Uploaded
                        Fonts</strong>
                    </p>

                    <ul class="p-0">
                      <div *ngIf="brandFontArray.length > 0"></div>
                      <li class="font-list-item truncate-line" *ngFor="let font of brandFontArray; let i = index; ">
                        <mat-icon class="green-tik-font"
                        [ngClass]="{ active: this.selectedFont == font }">check_circle</mat-icon>
                        <div type="radio" class="no-tik-font"
                        *ngIf="this.selectedFont != font" [ngClass]="{ 'show': this.selectedFont != font }"
                        (click)="selectFont(font)"></div>
                        <a class="dropdown-item text-md test truncate-line text"
                         style="font-family:'{{fontStyle(font)}}'"
                          >{{ font | fontFileNameTruncate : 55 }}
                        </a>
                        <img class="del-font font-delete-icon" (click)="deleteFont(font, i)" src="../../../../../assets/images/Delete.svg" />
                      </li>

                    </ul>
                  </li>
                  <li>
                    <p class="text-lg text-gray-2 mb-0 ms-3 py-2">
                      <strong>Others Fonts</strong>
                    </p>
                    <ul class="p-0">
                      <li class="font-list-item  truncate-line"
                        *ngFor=" let font of otherFontParentMap | keyvalue | slice:0:loadMoreFont "
                         (mouseenter)="loadFontWeightSubMenu(font)">

                        <mat-icon class="green-tik-font"
                          [ngClass]="{ active: this.selectedFont == font.value[0].name}">check_circle</mat-icon>
                        <div type="radio" class="no-tik-font"
                          *ngIf="this.selectedFont != font.value[0].name" [ngClass]="{ 'show': this.selectedFont != font.value[0].name }"
                        (click)="selectOtherFont(font)"></div>

                        <a class="dropdown-item text-md truncate-line text" style=" border:1px; font-family:'{{font.value[0].fontStyle}}'" >{{ font.key }}
                        </a>
                        <a class="arrowOpenWeightMenu" *ngIf="font.value  && font.value.length > 1" (mouseenter)="loadFontWeightSubMenu(font)">
                          <img src="../assets/images/icons/rightarrow.png" />
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li class="loadmore" *ngIf="loadMoreFont < otherFontParentMap.size" aria-labelledby="dropdownMenuClickableInside" (click)="loadMoreOtherFonts($event)"> Load more </li>
                </ul>

                <p class="text-xm ps-1 pt-1 pb-3">Select primary font</p>

                <button id="custom-button-svg" class="btn weight-600 text-xs btn-outline-gray-radius text-gray-2 btn-md-same-pad btnShadow d-flex align-items-center mt-2 py-1">
                  <span class="upload-icon"></span>
                  <input class="upload-file" type="file" id="fontUploader" #fontUploader accept=".otf, .ttf" (change)="uploadFont($event)" />
                  {{ true ? "Upload More" : "Upload a Font" }}
                </button>
                <p class="text-xm ps-1 font-brand-logo sm">
                  Supported font types (TTF, OTF)
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="row p-0 mx-0 my-3 pt-3 ps-3">
        <div class="col-md-6">
          <p class="text-md text-gray-3 mb-0">Brand colors</p>
          <div class="theme-color d-flex mt-4">
            <ul class="m-0 ps-0 pe-4">
              <label class="d-block text-md text-gray-2 pb-3">Theme color</label>
              <li>
                <div class="clr-field" [style.color]="oPrimaryColor ? oPrimaryColor : defaultColor">
                  <button aria-labelledby="clr-open-label"></button>
                  <input class="form-check-input coloris border" type="checkbox" [disabled]="!this.isEdit" [(ngModel)]="oPrimaryColor" [value]="oPrimaryColor" [ngModelOptions]="{ standalone: true }" (change)="setPrimaryColor($event)" id="checkboxNoLabel" aria-label="..." />
                  <figure class="color-pic no-bg">
                    <img class="color-picker" [ngClass]="{
                        white:
                          oPrimaryColor != '#ffffff' &&
                          oPrimaryColor != '#FFFFFF' &&
                          oPrimaryColor != undefined
                      }" src="../../../../../assets/images/check-icon.svg" width="16" height="16" *ngIf="oPrimaryColor != '' && oPrimaryColor != undefined" />
                    <img class="color-picker" [ngClass]="{
                        white:
                          oPrimaryColor != '#ffffff' &&
                          oPrimaryColor != '#FFFFFF' &&
                          oPrimaryColor != undefined
                      }" *ngIf="oPrimaryColor == '' || oPrimaryColor == undefined" src="../../../../../assets/images/color-picker.svg" width="16" height="16" />
                  </figure>
                </div>
              </li>
            </ul>
            <ul class="ms-2">
              <label class="d-block text-md text-gray-2 pb-3">Font Colors</label>
              <div class="secondary-color">
                <li class="secondary-color-list" *ngFor="let color of secondaryColor; let i = index">
                  <div class="clr-field" [style.color]="color.color ? color.color : defaultColor">
                    <button aria-labelledby="clr-open-label"></button>
                    <input [disabled]="!this.isEdit" class="form-check-input coloris border" type="checkbox" id="checkboxNoLabel" [(ngModel)]="color.color" [value]="color.color" [ngModelOptions]="{ standalone: true }" (change)="setSecondaryColor($event, i)" value="" aria-label="..." />
                  </div>
                  <figure class="color-pic no-bg">
                    <img class="color-picker" [ngClass]="{
                        white:
                          color.color != '#ffffff' &&
                          color.color != '#FFFFFF' &&
                          color.color != undefined
                      }" src="../../../../../assets/images/check-icon.svg" width="16" height="16" *ngIf="color.color != '' && color.color != undefined" />

                    <img class="color-picker" [ngClass]="{
                        white:
                          color.color != '#ffffff' &&
                          color.color != '#FFFFFF' &&
                          color.color != undefined
                      }" src="../../../../../assets/images/color-picker.svg" width="16" *ngIf="color.color == '' || color.color == undefined" height="16" />
                  </figure>
                  <img *ngIf="
                      this.isEdit &&
                      (color.color != '' || color.color != undefined)
                    " (click)="removeColor(i)" class="remove-color" src="../../../../../assets/images/Delete.svg" />
                </li>
                <li *ngIf="this.isEdit && showAddMoreSecondaryColorBtn">
                  <a (click)="addSecondaryColor()">
                    <figure>
                      <img class="add-color" src="../../../../../assets/images/Union.svg" />
                    </figure>
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- </div>
              </div>
            </div>
          </section> -->

  <!-- delete Brand pic Modal pop-up Start-->
  <div class="modal fade" id="DeleteBrandpic-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="btn-close cust-close" data-bs-dismiss="modal" aria-label="Close"></button>
          <h5>Delete Brand picture</h5>
          <p class="text-sm text-gray-3 pt-1 pb-4">
            Are you sure you want to delete your Brand picture?
          </p>
          <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" data-bs-dismiss="modal" (click)="deleteBrandPic()">Delete</a>
          <a href="javascript:void(0)" id="sidebarCollapse" class="mt-3 text-gray-3 text-sm" data-bs-dismiss="modal">Cancel</a>
        </div>
      </div>
    </div>
  </div>
  <!--delete Brand pic Modal pop-up End-->
  <nz-modal [(nzVisible)]="showDeleteBrandModal" nzCentered [nzWidth]="566" [nzFooter]="null" [nzClosable]="false" (nzOnCancel)="showDeleteBrandModal = false">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="btn-close cust-close closeRightBtn" (click)="showDeleteBrandModal = false"></button>

          <h5>Delete Brand picture</h5>
          <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">
            Are you sure you want to delete your Brand picture?
          </p>
          <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="showDeleteBrandModal = false">Cancel</a>
          <a href="javascript:void(0)" class="mt-3 text-gray-3 text-sm" (click)="deleteBrandPic()">Delete</a>
        </div>
      </div>
    </div>
  </nz-modal>
</form>

<!-- Modal pop-up Start-->
<nz-modal [(nzVisible)]="isModalVisible" nzCentered [nzWidth]="566" [nzFooter]="null" [nzClosable]="false" (nzOnCancel)="hidePopUP()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close cust-close closeRightBtn" (click)="hidePopUP()"></button>

        <h5>Brand Logo</h5>
        <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">
          Brand Logo has uploaded successfully
        </p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="hidePopUP()">Ok</a>
        <!-- <a href="javascript:void(0)"
class="mt-3 text-gray-3 text-sm" (click)="updateMediaLibrary(mediaObj)">asa</a> -->
      </div>
    </div>
  </div>
</nz-modal>

<!-- Modal Font pop-up Start-->
<nz-modal [(nzVisible)]="isFontModalVisible" nzCentered [nzWidth]="566" [nzFooter]="null" [nzClosable]="false" (nzOnCancel)="hideFontPopUP()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn-close cust-close closeRightBtn" (click)="hideFontPopUP()"></button>

        <h5>Brand Font</h5>
        <p class="text-sm text-gray-3 pt-1 pb-4 mb-0">
          Brand Font has uploaded successfully
        </p>
        <a class="weight-normal btn btn-secondary btn-round-6 cust-pad me-4" (click)="hideFontPopUP()">Ok</a>
      </div>
    </div>
  </div>
</nz-modal>