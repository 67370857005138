import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { cDataService } from './../../../services/data.service';
import { cSessionService } from './../../../services/session.service';
import { cTemplateConfigService } from './../../../services/template.service';
import { cWebSocketService } from './../../../services/websocket.service';
declare var $: any;
@Component({
  selector: 'app-ftue-preview',
  templateUrl: './ftue-preview.component.html',
  styleUrls: ['./ftue-preview.component.scss']
})
export class FtuePreviewComponent implements OnInit {
  @ViewChild('videoPreview') videoPreview: ElementRef;
  bFullHeader: boolean = false;
  sUserEmail: string;
  sSessionId: string;
  sVideoTitle : string;
  sVideoDuration : string;
  sVideoId: string;
  sErrorMessage : any;
  bDisplayErrorMsg : boolean = false;
  bLoading: boolean = false;
  sVideoURL : string;
  sThumbnail : string;
  playVideo : boolean = false;
  oGeneratedVideo : any = {"url" : "", "thumb" : "",};
  lVideoTimeout: any= [];
  nVideoDuration: number;
  nVideoTimeOut:any;
  oTimeOut:any;
  oPublicURL:any;
  fFormOtherSetting : any;
  constructor( private oWebSocketService: cWebSocketService, private oSessionService : cSessionService, private oDataService: cDataService, private oRouter : Router, private oTemplateConfigService : cTemplateConfigService ) 
  {
    if(this.oRouter.url === '/edit-video/timeline-view')
    {
      this.bFullHeader = true;
    }
  }

  ngOnInit(): void {
    this.PreviewComponent_InitializeJqueryCode();
    this.oWebSocketService.WebSocket_CreateWebSocket();
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.sVideoId = this.oSessionService.SessionService_GetVideoId();
    this.sVideoTitle = this.oSessionService.SessionService_GetVideoTitle();
    this.sVideoDuration = this.oSessionService.SessionService_GetVideoDuration();
    this.fFormOtherSetting = this.oSessionService.cSessionService_GetOtherSettingForm();
    console.log("Other Setting Form ===>>>",this.fFormOtherSetting);
    this.lVideoTimeout =this.oSessionService.SessionService_GetVideoTimeout();
    this.PreviewComponent_SettingVideoTimeout();
    
    let oVideo = this.oSessionService.SessionService_GetGeneratedVideo();
    if(oVideo)
    {
      this.oGeneratedVideo=oVideo;
    }
    else
    {
      let bGenerateVideoCheck = this.oSessionService.cSessionService_GetGeneratedVideoCheck();
      console.log("Generate video check ==> ",bGenerateVideoCheck); 
      if(bGenerateVideoCheck)
      {
        this.PreviewComponent_GenerateUserVideoRequest();
      }
      else
      {
        this.oRouter.navigate(['wizard/overview']);
      }
    }
  }
  PreviewComponent_SettingVideoTimeout()
  {
    this.nVideoDuration = this.oSessionService.SessionService_GetVideoDuration();
    this.nVideoTimeOut = this.lVideoTimeout.find(({duration})=> duration===this.nVideoDuration);
  }
  PreviewComponent_PlayVideo()
  {
    this.videoPreview.nativeElement.play(); 
  }

  PreviewComponent_PublishVideo()
  {
    let oPublishVideoRequest = 
    {
      session_id: this.sSessionId,
      email: this.sUserEmail,
      video_id : this.sVideoId,
      publish_status: "published"
    }
    this.oWebSocketService.WebSocket_RegisterEvent("update_video_publish_status_result", this.PreviewComponent_HandlePublishVideoResponse);
    console.log(" PreviewComponent_HandlePublishVideoResponse : Publish Video Request to WebSocket ==> ", oPublishVideoRequest);
    this.oWebSocketService.WebSocket_SendMessage("update_video_publish_status", JSON.stringify(oPublishVideoRequest));
  }
  ngOnDestroy()
  {
    this.oWebSocketService.WebSocket_Destroy();
    
  }
  PreviewComponent_HandlePublishVideoResponse = (InComingMessage) => 
  {
    console.log("PreviewComponent_HandlePublishVideoResponse : ==> ", InComingMessage);
    let oFormatedIncommingMessage = JSON.parse(InComingMessage);
    this.oPublicURL = oFormatedIncommingMessage.public_url;
    this.oSessionService.cSessionService_SetPublicURL(this.oPublicURL);
    console.log("The public URL is====>>>",this.oPublicURL);
    
    if (oFormatedIncommingMessage["errorMessage"]) 
    {
      this.sErrorMessage = oFormatedIncommingMessage["errorMessage"];
      this.bDisplayErrorMsg = true;
      this.bLoading=false;
    }
    else 
    {
      this.oTemplateConfigService.bSendGenerateVideoRequest.next(0);
      this.oRouter.navigate(['/published-video/timeline-view']);
      // this.oRouter.navigate(['/published-video/comment-section']);
    }
  }
  PreviewComponent_GenerateUserVideoRequest()
  {
    this.bLoading=true;
    this.oTimeOut = setTimeout(() => {
                                      this.bLoading=false;
                                      this.sErrorMessage = 'Pronto is experiencing network issues right now. We’re working on fixing this as soon as possible. Please refresh or try again later.';
                                      this.bDisplayErrorMsg=true;
                                     }, this.nVideoTimeOut.timeout);

    let oGenerateVideoRequest = 
    {
      session_id: this.sSessionId,
      email: this.sUserEmail,
      video_id: this.sVideoId
    }
    this.oWebSocketService.WebSocket_RegisterEvent("generate_user_video_result", this.PreviewComponent_GenerateUserVideoResponse);
    console.log(" PreviewComponent_GenerateUserVideoResponse : Generate Video Request to WebSocket=>", oGenerateVideoRequest);
    this.oWebSocketService.WebSocket_SendMessage("generate_user_video_request", JSON.stringify(oGenerateVideoRequest));
  }
  PreviewComponent_GenerateUserVideoResponse = async (InComingMessage) => 
  {
    console.log("Step 1 +++ PreviewComponent_GenerateUserVideoResponse : ==> ", InComingMessage);
    clearTimeout(this.oTimeOut);
    let oFormatedIncommingMessage = JSON.parse(InComingMessage);
    if (oFormatedIncommingMessage["errorMessage"]) 
    {
      this.sErrorMessage = oFormatedIncommingMessage["errorMessage"];
      this.bDisplayErrorMsg = true;
      this.bLoading=false;
    }
    else 
    {
      //get signed url of video
      this.oGeneratedVideo.url = await this.oDataService.DataService_GetSignedURLForDownload(oFormatedIncommingMessage.video_id);
      //get signed url of thumbnail
      this.oGeneratedVideo.thumb = await this.oDataService.DataService_GetSignedURLForDownload(oFormatedIncommingMessage.thumb);
      this.bDisplayErrorMsg = false;
      this.bLoading=false;
      this.oSessionService.cSessionService_SetGeneratedVideo(this.oGeneratedVideo);
    }
  }
  PreviewComponent_NavigateToDashboard()
  {
    this.oSessionService.SessionService_RemoveCreateVideoSettings();
    localStorage.clear();
    this.oRouter.navigateByUrl('/ftue-login');
  }
  FtuePreviewComponent_NaviagteToSignUp()
  {
    this.oSessionService.SessionService_RemoveCreateVideoSettings();
    this.oRouter.navigateByUrl('/createAccount');
    localStorage.clear();
  }
  PreviewComponent_SaveVideo()
  {
    this.oSessionService.SessionService_RemoveCreateVideoSettings();
    this.oRouter.navigateByUrl('/my-videos/draftsVideo');
  }
  PreviewComponent_InitializeJqueryCode()
    {
    $(document ).ready(function() {
      $("[data-sidebar]").click(function() {
        var sideBarId = $(this).attr("data-sidebar");
        $(".rightSideBar").removeClass("active");
        $(".sidebarOverlay").addClass("active");
        $(sideBarId).addClass("active");
      });
      
      
      $(".sidebarOverlay, .closeRightSideBar").click(function() {
        $(".rightSideBar").removeClass("active");
        $(".sidebarOverlay").removeClass("active");
        $("body").removeClass("sidbar_contentslide");
      });
      
      $("[data-sidebar2]").click(function() {
        var sideBarId = $(this).attr("data-sidebar2");
        $(".rightSideBarTutorialsVideo").removeClass("active");
        $(".sidebarOverlay2").addClass("active");
        $(sideBarId).addClass("active");
      });
      
      
      $(".sidebarOverlay2, .closeRightSideBar2").click(function() {
        $(".rightSideBarTutorialsVideo").removeClass("active");
        $(".sidebarOverlay2").removeClass("active");
        $("body").removeClass("sidbar_contentslide");
      });
    
      // data-custom-select
      $("[data-custom-select]").click(function() {
        $("body").addClass("sidbar_contentslide");
      });
      // end data-custom-select
    
    
      $(".customAccordionAction").on("click", function(){
        $(".customAccordion").removeClass("active");
        $(this).parent(".customAccordion").addClass("active");
      });
    
    // dropdownBtn
      $(".dropdownBtn .dropdown-item, .dropdownSelect .dropdown-item").on("click", function () {
        var dropVal = $(this).text();
        $(this).parent("li").siblings().children().removeClass("active");
        $(this).addClass("active");
        $(this).closest(".dropdownBtn, .dropdownSelect").find(".dropdownBtnText").text(dropVal);
    });
    // end dropdownBtn
    
    // $(".datepicker").datepicker({
    //   dateFormat: 'dd-mm-yy',
    // });
    
    
    });
  }


}
