import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { NzDrawerPlacement } from "ng-zorro-antd/drawer/public-api";
import { Observable } from "rxjs";
import { Subject } from "rxjs/internal/Subject";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { UserDataInfo } from "src/app/models/user";
import { cSessionService } from "src/app/services/session.service";
import { brand, brandList, UserList } from "src/app/models/admin";
import { Router } from "@angular/router";
import { AdminBrandManagerService } from "src/app/Managers/adminManager/admin-brand-manager.service";
import { Userservice } from "../users/service/user-service";
import { SuccessMessageModalService } from "src/app/services/success-message-modal.service";
import { ErrorService } from "src/app/services/error.service";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

@Component({
  selector: "app-manage-brand",
  templateUrl: "./manage-brand.component.html",
  styleUrls: ["./manage-brand.component.scss"],
})
export class ManageBrandComponent implements OnInit {
  showAddNewBrand = false;
  placementUser: NzDrawerPlacement = "right";
  selectedAddUsers: string[] = [];
  showFilterUserDrawer = false;
  userList: any = [];
  modelUserSearchChanged = new Subject<string>();
  isUserSearchLoading: boolean = false;
  searchUserText: string = "";
  addBrandFormSubmitted = false;
  public addBrandForm: FormGroup;
  listPages: number[] = [];
  showSearchBox = false;
  searchTerm = "";
  searchTermText = "";
  showSortByDD = false;
  searchBy = "created";

  limit = 20;
  offset = 0;
  currentPage = 1;
  totalPages = 1;

  brandList: brandList = {
    offset: 0,
    limit: this.limit,
    count: 0,
    result: [],
  };
  sessionId: string;
  email: string;
  userListLoopLimit = 3;
  isDeleteModalVisible = false;
  selectedBrand: brand;
  sortType = -1;
  industryList: any;
  @ViewChild('userListAutoComplete', { read: MatAutocompleteTrigger })
  autoCompleteUser: MatAutocompleteTrigger;
  showwarning = false;
  constructor(
    public oSessionService: cSessionService,
    private addBrandFormBuilder: FormBuilder,
    private userService: Userservice,
    private adminBrandManager: AdminBrandManagerService,
    public router: Router, private successMessageService: SuccessMessageModalService,
    public errorService: ErrorService,
    public _MatPaginatorIntl: MatPaginatorIntl,
  ) {
    this.sessionId = this.oSessionService.SessionService_GetSessionId();
    this.email = this.oSessionService.cSessionService_GetUserEmail();
    this.oSessionService.setAccountSettingHeaderName("Manage Brands");
    // this.email = "naamanm@gmail.com";
  }

  populatePagination() {
    if (this.brandList && this.brandList.total_count > 0) {
      const maxPage = Math.ceil(this.brandList?.total_count / this.limit) || 1;
      console.log(' Load page size ..', maxPage);
      if (maxPage > 1) {
        this.listPages = Array.from({ length: maxPage }, (_, i) => i + 1);
      } else {
        this.listPages = [1];
      }
    }
  }

  reloadBrandList() {
    let __this = this;
    this.adminBrandManager
      .getListBrand(
        this.email,
        this.sessionId,
        this.offset,
        this.limit,
        this.searchBy,
        this.sortType,
        this.searchTerm,
        'name',
        true
      )
      .then(function (brandList: brandList) {
        __this.brandList = brandList;
        __this.populatePagination();
      });
  }

  loadPage(page: number) {
    if (page < 1) {
      page = 1;
    } else {
      if (page > this.listPages.length) {
        page = 1;
      }
      this.brandList.result = [];
      this.currentPage = page;
      this.offset = page == 1 ? 0 : (page - 1) * this.limit - 1;
      this.reloadBrandList();
    }
  }
  ngAfterViewInit(): void {
    this.reloadBrandList();

    // TODO autocomplete Brands change trigger
    this.modelUserSearchChanged
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(async (value) => {
        if (value && value != "" && value.length > 1) {
          this.userList = await this.userService
            .searchUser(0, 30, "created", -1, "_id", value)
            .then((resList) => {
              return (this.userList = resList);
            });
          this.isUserSearchLoading = false;
          return this.userList;
        } else {
          return [];
        }
      });
  }

  ngOnInit(): void {
    this._MatPaginatorIntl.firstPageLabel = '';
    this._MatPaginatorIntl.lastPageLabel = '';
    this._MatPaginatorIntl.nextPageLabel = '';
    this._MatPaginatorIntl.previousPageLabel = '';
    window.addEventListener('scroll', this.scrollEvent, true);
    this.getIndustry();
  }

  scrollEvent = (event: any): void => {
    if (this.autoCompleteUser && this.autoCompleteUser.panelOpen) {
      this.autoCompleteUser.updatePosition();
    }
  }

  openAddNewBrandDrawer(): void {
    this.addBrandFormSubmitted = false;
    this.addBrandForm = this.addBrandFormBuilder.group({
      name: "",
      website: "",
      industry: "",
      users: "",
      searchUserText: "",
    });
    this.selectedAddUsers = [];
    this.showAddNewBrand = true;
  }
  closeAddNewBrandDrawer(): void {
    this.showAddNewBrand = false;
    this.closeWarningNote();
  }

  setAddUsers(user: string): void {
    if (this.selectedAddUsers.indexOf(user) === -1) {
      this.selectedAddUsers.push(user);
    }

    this.addBrandForm.controls["searchUserText"].setValue("");
    this.closeWarningNote();
  }

  removeAddUsers(user: string): void {
    var indexUsers = this.selectedAddUsers.indexOf(user);
    if (indexUsers > -1) {
      this.selectedAddUsers.splice(indexUsers, 1);
    }
  }

  async addNewBrand(): Promise<any> {
    console.log("add user called");
    this.addBrandFormSubmitted = true;
    if (this.addBrandForm.invalid || this.showwarning) {
      // TODO show Error notification
      console.log("form values r not valid ..");
    } else {
      // TODO add brand

      const selectedIndustry = this.industryList.result.filter((item: any) => {
        return (item.name === this.addBrandForm.controls["industry"].value);
      });

      const brandInfo: brand = {
        // _id: this.addBrandForm.controls["email"].value,
        name: this.addBrandForm.controls["name"].value,
        website: this.addBrandForm.controls["website"].value,
        industry: this.addBrandForm.controls["industry"].value,
        industry_icon: (selectedIndustry && selectedIndustry.length > 0) ? selectedIndustry[0].icon : undefined,
        users: this.selectedAddUsers.length > 0 ? this.selectedAddUsers : [],

      };
      await this.adminBrandManager.addBrand(brandInfo).then(async (res: any) => {
        await this.addUserBrand(res.id.$oid);
        this.successMessageService.display("Brand has been created.", "Add Brand", true, true);
        this.loadNewlyAddedBrand(res.id.$oid);
      })
        .catch((err) => {
          this.errorService.handleError(err, err.errorTitle, true);
        });
      this.showAddNewBrand = false;
    }
  }

  async addUserBrand(brandId: string) {
    let userIds = this.selectedAddUsers;
    return await this.adminBrandManager.addUserBrand([brandId], userIds).then((res) => { });
  }

  loadNewlyAddedBrand(brandId) {
    this.adminBrandManager.getBrandProfile(brandId).then((brand: brand) => {
      this.brandList.result.unshift(brand);
      ++this.brandList.count;
      ++this.brandList.total_count;
    });
  }

  loadBrandProfile(id: any) {
    console.log("blist-id", id);
    this.router.navigate(["/account-setting/manage-brand/account", id.$oid]);
  }

  //Filter User drawer
  openFilterUserDrawer(): void {
    this.showFilterUserDrawer = true;
  }
  closeFilterUserDrawer(): void {
    this.showFilterUserDrawer = false;
  }

  searchBrand() {
    this.offset = 0;
    this.searchTerm = this.searchTermText;
    this.reloadBrandList();
  }

  clearSearchTerm() {
    this.offset = 0;
    this.searchTerm = "";
    this.searchTermText = "";
    this.reloadBrandList();
  }

  sortBySearchResult(value) {
    this.offset = 0;
    this.searchBy = value;
    this.showSortByDD = false;
    this.hideSearchSortTypeDD(undefined);
    this.sortType = -1;
    this.reloadBrandList();
  }

  openSearchSortTypeDD() {
    this.showSortByDD = this.showSortByDD ? false : true;
  }

  hideSearchSortTypeDD($event) {
    if (this.showSortByDD) {
      const __this = this;
      setTimeout(() => {
        __this.showSortByDD = false;
      }, 200);
    }
  }

  viewAllSelectedUsers(val) {
    this.userListLoopLimit = val;
  }

  handleCancel() {
    this.isDeleteModalVisible = false;
  }


  async deleteBrand() {
    const response = await this.adminBrandManager.deleteBrand(this.selectedBrand?._id?.$oid);
    if (response) {
      const indexRecrod = this.brandList?.result.findIndex(
        (item) => item?._id?.$oid == this.selectedBrand?._id?.$oid
      );
      this.successMessageService.display("Brand has been deleted.", "Delete Brand", true, true);
      if (indexRecrod > -1) {
        this.isDeleteModalVisible = false;
        this.brandList.result.splice(indexRecrod, 1);
        --this.brandList.total_count;
        --this.brandList.count;
      }
    }
  }

  sortTypeSearch(val: number) {
    this.sortType = val;
    this.reloadBrandList();
  }


  loadPageEvent($event) {
    if ($event.pageSize != this.limit) {
      this.limit = $event.pageSize;
      this.loadPage(1);
    } else {
      this.loadPage($event.pageIndex + 1);
    }
  }
  getIndustry() {
    let __this = this;
    this.adminBrandManager
      .getIndustryList()
      .then((res: any) => {
        console.log("chkres", res);
        this.industryList = res;

      });
  }
  openWarningNote() {
    console.log("chkres11");
    this.showwarning = true;
  }
  closeWarningNote() {
    console.log("chkres11");
    this.showwarning = false;
    this.addBrandForm.controls["searchUserText"].setValue("");
  }

}
