import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { cDataService } from './../../../services/data.service';
import { cSessionService } from './../../../services/session.service';
import { cTemplateConfigService } from './../../../services/template.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { CreateVideoManagerService } from '../../../Managers/create-video-manager.service';
import { VideoManagerService } from '../../../Managers/video-manager.service';
import { Subscription } from 'rxjs';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
declare var $: any;
@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit, OnDestroy {
  @ViewChild('videoPreview') videoPreview: ElementRef;
  bFullHeader: boolean = false;
  sUserEmail: string;
  sSessionId: string;
  sVideoTitle: string;
  sVideoDuration: string;
  sVideoId: string;
  sErrorMessage: any;
  bDisplayErrorMsg: boolean = false;
  bLoading: boolean = false;
  sVideoURL: string;
  sThumbnail: string;
  playVideo: boolean = false;
  oGeneratedVideo: any = { "url": "", "thumb": "", };
  lVideoTimeout: any = [];
  nVideoDuration: number;
  nVideoTimeOut: any;
  oTimeOut: any;
  oPublicURL: any;
  bResponse: boolean = false;
  bDisplayEditVideoLoader: boolean = false;
  oClosePopup: any = 0;

  constructor(private oWebSocketService: cWebSocketService, private oSessionService: cSessionService, public videoManger: CreateVideoManagerService, public videoListManger: VideoManagerService, private oDataService: cDataService, private oRouter: Router, private oTemplateConfigService: cTemplateConfigService) {
    if (this.oRouter.url === '/edit-video/timeline-view') {
      this.bFullHeader = true;
    }
  }
  sub: Subscription;
  ngOnInit(): void {
    this.videoManger.isEditMode = true;
    this.sub = this.videoManger.submitForm.subscribe(c => {
      this.videoListManger.selectedVideo = this.videoManger.selectedVideo;
      this.oRouter.navigateByUrl('/my-videos/draftsVideo');
    });
    this.videoManger.previewMode = true;
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();


    this.bLoading = true;

    this.videoManger.generateVideo(this.sUserEmail, this.sSessionId, this.videoManger.selectedVideo.fpath).then(res => {


      this.videoManger.generateVideoStatus(this.sUserEmail, this.sSessionId, this.videoManger.gv_task_id).then(rr => {

        this.videoManger.isEditMode = true;

        this.videoListManger.selectedVideo = this.videoManger.selectedVideo;
        this.bLoading = false;
        this.bResponse = true;
      }).catch(error => {
        this.bLoading = false;
      })
    }).catch(err => {
      this.bLoading = false;

    });
  }
  PreviewComponent_SettingVideoTimeout() {
    this.nVideoDuration = this.oSessionService.SessionService_GetVideoDuration();
    if (this.lVideoTimeout) {
      this.nVideoTimeOut = this.lVideoTimeout.find(({ duration }) => duration === this.nVideoDuration);
    }
    else {
      this.oSessionService.SessionService_SetVideoTimeout();
      this.lVideoTimeout = this.oSessionService.SessionService_GetVideoTimeout();
      this.nVideoTimeOut = this.lVideoTimeout.find(({ duration }) => duration === this.nVideoDuration);
    }
  }
  PreviewComponent_PlayVideo() {
    this.videoPreview.nativeElement.play();
  }


  publishVideo() {
    this.videoManger.updateVideoStatus(this.sUserEmail, this.sSessionId, this.videoManger.selectedVideo.fpath, "published").then(res => {
      this.videoListManger.selectedVideo = this.videoManger.selectedVideo;
      this.videoListManger.selectedVideo.public_url = res['public_url'];
      this.oRouter.navigate(['/published-video/timeline-view']);
    }).catch(err => {

      this.sErrorMessage = 'An error occurred while publishing video. Please try again.';
      this.bDisplayErrorMsg = true;
    });
  }
  PreviewComponent_PublishVideo() {
    let oPublishVideoRequest =
    {
      session_id: this.sSessionId,
      email: this.sUserEmail,
      video_id: this.sVideoId,
      publish_status: "published"
    }
    this.oWebSocketService.WebSocket_RegisterEvent("update_video_publish_status_result", this.PreviewComponent_HandlePublishVideoResponse);
    console.log(" PreviewComponent_HandlePublishVideoResponse : Publish Video Request to WebSocket ==> ", oPublishVideoRequest);
    this.oWebSocketService.WebSocket_SendMessage("update_video_publish_status", JSON.stringify(oPublishVideoRequest));
  }
  ngOnDestroy() {

    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.videoManger.previewMode = false;

    if (this.oTimeOut) clearTimeout(this.oTimeOut);
  }
  PreviewComponent_HandlePublishVideoResponse = (InComingMessage) => {
    console.log("PreviewComponent_HandlePublishVideoResponse : ==> ", InComingMessage);
    let oFormatedIncommingMessage = JSON.parse(InComingMessage);
    this.oPublicURL = oFormatedIncommingMessage.public_url;
    this.oSessionService.cSessionService_SetPublicURL(this.oPublicURL);
    console.log("The public URL is====>>>", this.oPublicURL);

    if (oFormatedIncommingMessage["errorMessage"]) {
      this.sErrorMessage = 'An error occurred while publishing video. Please try again.';
      this.bDisplayErrorMsg = true;
      this.bLoading = false;
    }
    else {
      this.oTemplateConfigService.bSendGenerateVideoRequest.next(0);
      this.oRouter.navigate(['/published-video/timeline-view']);
    }
  }
  PreviewComponent_SendUserVideoRequest() {
    this.bLoading = true;
    let oOtherSettingForm = this.oSessionService.cSessionService_GetGenerateVideoPayload();
    this.oWebSocketService.WebSocket_SendMessage("user_video_request", JSON.stringify(oOtherSettingForm));
  }

  PreviewComponent_HandleCreateVideoResponse = (InComingMessage) => {
    if (JSON.parse(InComingMessage)["errorMessage"]) {
      console.error("PreviewComponent_HandleCreateVideoResponse : Error ==> ", InComingMessage);
      this.sErrorMessage = 'An error occurred while creating video. Please try again.';
      $('#error-popup').modal("show");
      this.bDisplayErrorMsg = true;
      setTimeout(() => { this.bDisplayErrorMsg = false }, 3000);
    }
    else {
      console.log("PreviewComponent_HandleCreateVideoResponse : Response ===>>>", JSON.parse(InComingMessage));
      this.PreviewComponent_GenerateUserVideoRequest();
    }
  }
  PreviewComponent_GenerateUserVideoRequest() {
    this.bLoading = true;
    this.oTimeOut = setTimeout(() => {
      this.bLoading = false;
      this.sErrorMessage = 'Pronto is experiencing network issues right now. We’re working on fixing this as soon as possible. Please refresh or try again later.';
      this.bDisplayErrorMsg = true;
    }, this.nVideoTimeOut.timeout);

    let oGenerateVideoRequest =
    {
      session_id: this.sSessionId,
      email: this.sUserEmail,
      video_id: this.sVideoId
    }
    this.oWebSocketService.WebSocket_RegisterEvent("generate_user_video_result", this.PreviewComponent_GenerateUserVideoResponse);
    console.log(" PreviewComponent_GenerateUserVideoResponse : Generate Video Request to WebSocket=>", oGenerateVideoRequest);
    this.oWebSocketService.WebSocket_SendMessage("generate_user_video_request", JSON.stringify(oGenerateVideoRequest));
  }
  PreviewComponent_GenerateUserVideoResponse = async (InComingMessage) => {
    console.log("PreviewComponent_GenerateUserVideoResponse : ==> ", InComingMessage);
    let oFormatedIncommingMessage = JSON.parse(InComingMessage);
    clearTimeout(this.oTimeOut);
    if (oFormatedIncommingMessage["errorMessage"]) {
      this.sErrorMessage = 'An error occurred while creating video. Please try again.';
      this.bDisplayErrorMsg = true;
      this.bResponse = true;
      setTimeout(() => { this.bLoading = false; }, 2000);
    }
    else {
      //get signed url of video
      this.oGeneratedVideo.url = await this.oDataService.DataService_GetSignedURLForDownload(oFormatedIncommingMessage.video_id);
      //get signed url of thumbnail
      this.oGeneratedVideo.thumb = await this.oDataService.DataService_GetSignedURLForDownload(oFormatedIncommingMessage.thumb);
      this.bResponse = true;
      this.bDisplayErrorMsg = false;
      setTimeout(() => { this.bLoading = false; }, 2000);
      this.oSessionService.cSessionService_SetGeneratedVideo(this.oGeneratedVideo);
    }
  }
  PreviewComponent_NavigateToDashboard() {
    this.oSessionService.SessionService_RemoveCreateVideoSettings();
    this.oRouter.navigateByUrl('/dashboard/myvideos');
  }
  PreviewComponent_SaveVideo() {
    this.oSessionService.SessionService_RemoveCreateVideoSettings();
    this.oSessionService.cSessionService_SetUserVideosCount(1)
    this.oRouter.navigateByUrl('/my-videos/draftsVideo');
  }
  PreviewComponent_HideLoader(bEmittedLoadingController: any) {
    this.oTimeOut = setTimeout(() => {
      this.bDisplayEditVideoLoader = false;
      this.sErrorMessage = 'Pronto is experiencing network issues right now. We’re working on fixing this as soon as possible. Please refresh or try again later.';
      this.bDisplayErrorMsg = true;
    }, this.nVideoTimeOut.timeout);
    this.bDisplayEditVideoLoader = bEmittedLoadingController;
  }
  PreviewComponent_GetClickLocation(event) {
    this.oClosePopup = 0;
    if (event.clientX <= 65) {
      this.oClosePopup = 1;
      setTimeout(() => { this.oClosePopup = 0; }, 200);
    }
  }
  // modal scheduled video
  isVisible = false;
  showModal(): void {
    this.isVisible = true;
  }
  handleOk(): void {
    this.isVisible = true;
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  visibleLocation = false;
  placementLocation: NzDrawerPlacement = 'right';
  openLocation(): void {
    this.visibleLocation = true;
  }
  closeLocation(): void {
    this.visibleLocation = false;
  }


}
